 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="80px">
			<el-form-item class="el_form_item" label="TMS单号">
				<el-input class="el_input" v-model="form.tms_ord_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="客户单号">
				<el-input class="el_input" v-model="form.cus_ord_num" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="主单号">
				<el-input class="el_input" v-model="form.mbl" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="箱型">
				<el-select class="el_input" v-model="form.container_type_name" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option v-for="(item,index) in container_type_list" :key="index" :label="item.name" :value="item.name"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="箱号">
				<el-input class="el_input" v-model="form.container_number" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="进行中" value="1"></el-option>
					<el-option label="已完成" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<!-- <el-button type="success" @click="add_view_open()">添加</el-button> -->
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="主单号/添加/修改时间" width="200">
					<template slot-scope="scope">
						<div>MBL:{{scope.row.mbl?scope.row.mbl:'无'}}</div>
						<div>{{scope.row.create_time_text}}</div>
						<div>{{scope.row.update_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="客户单号">
					<template slot-scope="scope">
						<div>{{scope.row.cus_ord_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="POD/TMS单号">
					<template slot-scope="scope">
						<div>POD:{{scope.row.pod?scope.row.pod:'无'}}</div>
						<div>{{scope.row.tms_ord_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货物信息">
					<template slot-scope="scope">
						<div v-for="(item,index) in scope.row.cargo_info">
							{{item.factory_name}}:{{item.quantity}}<span v-if="item.mark">({{item.mark}})</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="提货日期/提货备注">
					<template slot-scope="scope">
						<div>{{scope.row.pickup_date_text}}</div>
						<div>{{scope.row.pickup_mark}}</div>
					</template>
				</el-table-column>
				<el-table-column label="箱型/箱号/封号">
					<template slot-scope="scope">
						<div>{{scope.row.container_type_name}}</div>
						<div>{{scope.row.container_number}}</div>
						<div>{{scope.row.container_seal_number}}</div>
					</template>
				</el-table-column>
				<el-table-column label="船名/航次">
					<template slot-scope="scope">
						<div>{{scope.row.vessel}}</div>
						<div>{{scope.row.voyage}}</div>
					</template>
				</el-table-column>
				<el-table-column label="ETD/ATD" width="">
					<template slot-scope="scope">
						<div>{{scope.row.etd_text}}</div>
						<div>{{scope.row.atd_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="ETA/ATA" width="">
					<template slot-scope="scope">
						<div>{{scope.row.eta_text}}</div>
						<div>{{scope.row.ata_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="截单/装箱时间" width="">
					<template slot-scope="scope">
						<div>{{scope.row.cut_off_time_text}}</div>
						<div>{{scope.row.loading_date_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="放关/送货/还箱" width="">
					<template slot-scope="scope">
						<div>{{scope.row.clearance_release_time_text}}</div>
						<div>{{scope.row.delivery_time_text}}</div>
						<div>{{scope.row.emtpy_container_return_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="状态" prop="status_text" width="70"></el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="50">
					<template slot-scope="scope">
						<el-button @click="edit_view_open(scope.row)" size="mini" type="text">详情</el-button>
						<!-- <el-button v-if="scope.row.status == 1" @click="del(scope.row)" size="mini" type="text" style="color:red">删除</el-button> -->
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 修改弹出层 -->
		<edit 
			:is_show="edit_view.is_show" 
			:data="edit_view.data" 
			@closed="edit_view_close" 
		></edit>

	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import edit from './edit.vue'
	export default {
		components:{
			edit,
		},
		data() {
			return {

				//基础数据
				container_type_list:[],

				//搜索条件
				form: {
					tms_ord_num:'',
					cus_ord_num:'',
					mbl:'',
					container_type_name:'',
					container_number:'',
					factory_id:'',
					factory_name:'',
					status:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//添加弹窗
				add_view:{
					is_show:0,
				},

				//修改弹窗
				edit_view:{
					is_show:0,
					data:{},
				},
			}
		},
		computed:{
			...mapState(['basic_data'])
		},
		created() {
			
			//集装箱类型
			this.container_type_list=this.basic_data.container_type.arr;

			//读取页面数据
			this.page_ser()
		},
		methods: {

			//删除
			del(item){

				if(item.status != 1){
					this.$my.other.msg({
						type:'warning',
						str:"此项不能删除"
					});
					return;
				}

				//询问
				this.$my.other.confirm({
					content:"点击确定删除此项",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'business',
								ctr:'business_container_del_by_admin',
								business_container_id:item.business_container_id,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.page_ser()
							},
						});
					}
				});
			},

			//添加视图
			add_view_open(){//打开
				this.add_view.is_show++
			},
			add_view_close(){//关闭
				this.add_view.is_show=0
				this.page_ser()
			},

			//修改视图
			edit_view_open(item){//打开
				if(item.status != 1){
					this.$my.other.msg({
						type:'warning',
						str:"只有'未提箱'状态下可以修改"
					});
					return;
				}
				this.edit_view.data=item
				this.edit_view.is_show++
			},
			edit_view_close(){//关闭
				this.edit_view.is_show=0
				this.page_ser()
			},

			//搜索
			page_ser(){
				this.page.p=1
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){
				this.page.num=num
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				this.page.p=p
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'business',
						ctr:'business_container_list_by_customer',
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//取出各种info
							item.cargo_info=JSON.parse(item.cargo_info)

							//创建/修改时间
							item.create_time_text=this.$my.other.totime(item.create_time);
							item.update_time_text=this.$my.other.totime(item.update_time);

							//状态
							switch(item.status){
								case '1':item.status_text="进行中";break;
								case '2':item.status_text="已完结";break;
							}

							//时效
							item.pickup_date_text=item.pickup_date>0?this.$my.other.todate(item.pickup_date):'';
							item.etd_text=item.etd>0?this.$my.other.todate(item.etd):'';
							item.atd_text=item.atd>0?this.$my.other.todate(item.atd):'';
							item.loading_date_text=item.loading_date>0?this.$my.other.todate(item.loading_date):'';
							item.eta_text=item.eta>0?this.$my.other.todate(item.eta):'';
							item.cut_off_time_text=item.cut_off_time>0?this.$my.other.todate(item.cut_off_time):'';
							item.ata_text=item.ata>0?this.$my.other.totime(item.ata):'';
							item.clearance_release_time_text=item.clearance_release_time>0?this.$my.other.totime(item.clearance_release_time):'';
							item.delivery_time_text=item.delivery_time>0?this.$my.other.totime(item.delivery_time):'';
							item.emtpy_container_return_time_text=item.emtpy_container_return_time>0?this.$my.other.totime(item.emtpy_container_return_time):'';
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_div{
		width: 80%;
		background-color: #F5F7FA;
		border-color: #E4E7ED;
		color: #C0C4CC;
		cursor: pointer;
		height: 32px;
    	line-height: 32px;
		border: 1px solid #DCDFE6;
		border-radius: 4px;
		padding: 0 15px;
	}
	.el_input{
		width:94%;
	}
	.btn_left{
		margin: 0;
	}
</style>