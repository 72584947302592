<template>
	<div>
		<el-button v-if="is_edit" @click="cargo_add" type="text" size="mini" >增加一行</el-button>
		<div style="height: 160px;margin-top:5px">
			<el-table :data="list" :border="true" :stripe="true" size="mini" height="100%">
				<el-table-column label="工厂" >
					<template slot-scope="scope">
						<el-input class="el_inner_width" v-model="scope.row.factory_name" size="mini" :disabled="true">
							<el-button slot="append" @click="factory_choose_open(scope.row)">选择</el-button>
						</el-input>
					</template>
				</el-table-column>
				<el-table-column label="件数" width="80">
					<template slot-scope="scope">
						<el-input v-model="scope.row.quantity" size="mini" @input="sub()"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="件数备注" width="150">
					<template slot-scope="scope">
						<el-input v-model="scope.row.mark" size="mini" @input="sub()"></el-input>
					</template>
				</el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="50" v-if="is_edit">
					<template slot-scope="scope">
						<el-button @click="list_del(scope.$index)" size="mini" type="text">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		
	</div>
</template>
<script>
	export default {
		components:{
		},
		model:{
			prop:'data',
			event:'change'
		},
		props: {
			data: Array,
			is_edit:Boolean,
		},
		data() {
			return {
				
				//数据列表
				list:[],

				//工厂选择
				factory_choose:{
					item:{},
					is_show:0,
				},

			};
		},
		created(){
			this.list=[];
			for(var item of this.data){
				this.list.push({
					factory_id:item.factory_id,
					factory_name:item.factory_name,
					quantity:item.quantity,
					mark:item.mark,
				});
			}
		},
 	 	methods: {

			//数据提交
			sub(){
				this.$emit('change',this.list);
			},

			//添加
			cargo_add(index = -1, data) {
				if (index == -1) {
					index = this.list.length;
				}
				if (!data) {
					data = {
						factory_id:'',
						factory_name: '',
						quantity: '',
						mark: '',
					};
				}
				this.list.splice(index, 0, {
					factory_id:data.factory_id,
					factory_name:data.factory_name,
					quantity: data.quantity,
					mark: data.mark,
				});
			},
			list_del(index) {
				this.list.splice(index, 1);
				this.sub()
			},
		
			//工厂选择
			factory_choose_open(item){//打开
				this.factory_choose.item=item;
				this.factory_choose.is_show++
			},
			factory_choosed(obj){//关闭
				this.factory_choose.is_show=0
				this.factory_choose.item.factory_id=obj.factory_id
				this.factory_choose.item.factory_name=obj.name
				this.sub();
			},
		},
	};
</script>
<style lang="scss" scoped>
	
</style>