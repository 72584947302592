<template>
	<el-dialog title="集装箱业务详情" top="1vh" width="90%" :visible.sync="is_show_in_page" @closed="closed">

		<!-- 表单 -->
    	<el-form class="form" label-width="80px" disabled>

			<div class="big_tit" style="margin: 0">业务数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="主单号" style="width:25%">
					<el-input class="el_inner_width" v-model="form.mbl" :disabled="mbl_has_ser" clearable>
						<el-button slot="append" @click="mbl_ser">查找</el-button>
					</el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="TMS单号" style="width:25%">
					<el-input class="el_inner_width" v-model="form.tms_ord_num" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="客户单号" style="width:25%">
					<el-input class="el_inner_width" v-model="form.cus_ord_num" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="POD" style="width:25%">
					<el-input class="el_inner_width" v-model="form.pod" clearable></el-input>
				</el-form-item>
      		</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="提货日期" style="width:25%">
					<el-date-picker 
						class="el_inner_width"
						v-model="form.pickup_date" 
						type="date" align="center" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="提货备注" style="width:25%">
					<el-input class="el_inner_width" v-model="form.pickup_mark" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="船名" style="width:25%">
					<el-input class="el_inner_width" v-model="form.vessel" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="航次" style="width:25%">
					<el-input class="el_inner_width" v-model="form.voyage" clearable></el-input>
				</el-form-item>
      		</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="装箱时间" style="width:25%">
					<el-date-picker 
						class="el_inner_width"
						v-model="form.loading_date" 
						type="date" align="center" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="截单日期" style="width:25%">
					<el-date-picker 
						class="el_inner_width"
						v-model="form.cut_off_time" 
						type="date" align="center" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="ETD" style="width:25%">
					<el-date-picker 
						class="el_inner_width"
						v-model="form.etd" 
						type="date" align="center" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="ETA" style="width:25%">
					<el-date-picker 
						class="el_inner_width"
						v-model="form.eta" 
						type="date" align="center" 
					></el-date-picker>
				</el-form-item>
      		</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="ATD" style="width:25%">
					<el-date-picker 
						class="el_inner_width"
						v-model="form.atd" 
						type="datetime" align="center" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="ATA" style="width:25%">
					<el-date-picker 
						class="el_inner_width"
						v-model="form.ata" 
						type="datetime" align="center" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="放关时间" style="width:25%">
					<el-date-picker 
						class="el_inner_width"
						v-model="form.clearance_release_time" 
						type="datetime" align="center" 
					></el-date-picker>
				</el-form-item>
				<el-form-item class="el_form_item" label="送货时间" style="width:25%">
					<el-date-picker 
						class="el_inner_width"
						v-model="form.delivery_time" 
						type="datetime" align="center" 
					></el-date-picker>
				</el-form-item>
      		</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="还箱时间" style="width:25%">
					<el-date-picker 
						class="el_inner_width"
						v-model="form.emtpy_container_return_time" 
						type="datetime" align="center" 
					></el-date-picker>
				</el-form-item>
      		</div>


      		<div class="big_tit">
				<div>集装箱列表</div>
				<el-button @click="business_container_detail_add" type="primary" size="mini" style="position: absolute; left: 120px; top: 8px">增加一行</el-button>
	      	</div>
      		<div class="tab_height">
        		<el-table :data="business_container_detail" :border="true" :stripe="true" size="small" height="100%">
					<el-table-column label="箱型" width="170">
						<template slot-scope="scope">
							<el-select v-model="scope.row.container_type_name" style="width:100%" :disabled="scope.row.status!=1" clearable>
								<el-option v-for="(item,index) of container_type_list" :label="item.name" :value="item.name" :key="index"></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column label="货物信息" >
						<template slot-scope="scope">
							<cargoInfo v-model="scope.row.cargo_info" :is_edit="false"></cargoInfo>
						</template>
					</el-table-column>
					<el-table-column label="箱号" >
						<template slot-scope="scope">
							<el-input v-model="scope.row.container_number" clearable></el-input>
						</template>
					</el-table-column>
					<el-table-column label="封号" >
						<template slot-scope="scope">
							<el-input v-model="scope.row.container_seal_number" clearable></el-input>
						</template>
					</el-table-column>
        		</el-table>
      		</div>
    	</el-form>
		
  	</el-dialog>
</template>
<script>
	import {mapState} from 'vuex'
	import cargoInfo from './cargo_info.vue'
	export default {
		components:{
			cargoInfo,
		},
		props: {
			is_show: Number,
			data:Object,
		},
		data() {
			return {

				//基础数据
				container_type_list:[],

				//是否显示
				is_show_in_page: false,

				//booking是否搜索过
				mbl_has_ser:false,

				//表单
				form: {
					tms_ord_num:'',
					cus_ord_num:'',
					mbl:'',
					pod:'',
					pickup_date:null,
					pickup_mark:'',
					vessel:'',
					voyage:'',
					loading_date:null,
					cut_off_time:null,
					etd:null,
					eta:null,
					atd:null,
					ata:null,
					clearance_release_time:null,
					delivery_time:null,
					emtpy_container_return_time:null,
				},

				//堆场可预约时段列表
				business_container_detail:[],
			};
		},
		watch: {
			is_show(new_data) {
				if (new_data) {

					//打开弹出层
					this.is_show_in_page = true;

					//初始化
					this.init();

				} else this.is_show_in_page = false;
			},
		},
		computed:{
			...mapState(['basic_data'])
		},
		created(){

			//集装箱类型
			this.container_type_list=this.basic_data.container_type.arr;
		},
 	 	methods: {

			//页面关闭
			closed(){
				this.$emit("closed");
			},

			//堆场可预约时段保存
			sub(){
				if(!this.mbl_has_ser){
					this.$my.other.msg({
						type:'warning',
						str:"请先查找主单号信息"
					});
					return;
				}
				if(!this.form.mbl.trim()){
					this.$my.other.msg({
						type: "warning",
						str: `缺少主单号编号`,
					});
					return;
				}

				//时效字段处理
				let pickup_date=this.form.pickup_date?this.form.pickup_date.getTime():0
				pickup_date=(pickup_date/1000).toFixed(0)

				let loading_date=this.form.loading_date?this.form.loading_date.getTime():0
				loading_date=(loading_date/1000).toFixed(0)

				let cut_off_time=this.form.cut_off_time?this.form.cut_off_time.getTime():0
				cut_off_time=(cut_off_time/1000).toFixed(0)

				let etd=this.form.etd?this.form.etd.getTime():0
				etd=(etd/1000).toFixed(0)

				let eta=this.form.eta?this.form.eta.getTime():0
				eta=(eta/1000).toFixed(0)

				let atd=this.form.atd?this.form.atd.getTime():0
				atd=(atd/1000).toFixed(0)

				let ata=this.form.ata?this.form.ata.getTime():0
				ata=(ata/1000).toFixed(0)

				let clearance_release_time=this.form.clearance_release_time?this.form.clearance_release_time.getTime():0
				clearance_release_time=(clearance_release_time/1000).toFixed(0)

				let delivery_time=this.form.delivery_time?this.form.delivery_time.getTime():0
				delivery_time=(delivery_time/1000).toFixed(0)

				let emtpy_container_return_time=this.form.emtpy_container_return_time?this.form.emtpy_container_return_time.getTime():0
				emtpy_container_return_time=(emtpy_container_return_time/1000).toFixed(0)

				//汇集需要修改的集装箱业务
				let business_container_edit=[];
				let business_container_add=[];
				for(let index in this.business_container_detail){

					//取出item
					index = parseInt(index);
					let item=this.business_container_detail[index];
					if(item.status!=1)continue;

					//重要数据校验
					if(!item.container_type_name.trim()){
						this.$my.other.msg({
							type: "warning",
							str: `第 ${index + 1} 条缺少箱型`,
						});
						return;
					}

					//状态区分
					if(item.business_container_id){//有id,置入待修改数据
						business_container_edit.push({
							where:{
								business_container_id:item.business_container_id,
							},
							set:{
								container_type_name:item.container_type_name,
								cargo_info:item.cargo_info,
								container_number: item.container_number,
								container_seal_number: item.container_seal_number,
								...this.form,
								pickup_date:pickup_date,
								loading_date:loading_date,
								cut_off_time:cut_off_time,
								etd:etd,
								eta:eta,
								atd:atd,
								ata:ata,
								clearance_release_time:clearance_release_time,
								delivery_time:delivery_time,
								emtpy_container_return_time:emtpy_container_return_time,
							}
						});
					}else{//没有id,置入待添加数据
						business_container_add.push({
							container_type_name:item.container_type_name,
							cargo_info:item.cargo_info,
							container_number: item.container_number,
							container_seal_number: item.container_seal_number,
							...this.form,
							pickup_date:pickup_date,
							loading_date:loading_date,
							cut_off_time:cut_off_time,
							etd:etd,
							eta:eta,
							atd:atd,
							ata:ata,
							clearance_release_time:clearance_release_time,
							delivery_time:delivery_time,
							emtpy_container_return_time:emtpy_container_return_time,
						})
					}
				}

				//询问
				this.$my.other.confirm({
					content:"点击确定保存集装箱业务数据",
					confirm:()=>{

						//待修改数据
						if(business_container_edit.length > 0){

							this.$my.net.req({
								data:{
									mod:"business",
									ctr:"business_container_edit_by_admin",
									edit_list:business_container_edit,
								},
								callback:(data)=>{
									this.$my.other.msg({
										type:'success',
										str:"需修改部分保存成功"
									});
								},
							});
						}

						//待添加数据
						if(business_container_add.length > 0){

							this.$my.net.req({
								data:{
									mod:"business",
									ctr:"business_container_add_by_admin",
									add_list:business_container_add,
								},
								callback:(data)=>{
									this.$my.other.msg({
										type:'success',
										str:"需添加部分保存成功"
									});
								},
							});
						}
					}
				});
			},

			//booking信息查找
			mbl_ser(){

				if(!this.form.mbl.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"请输入主单号"
					});
					return;
				}

				//调用接口
				this.$my.net.req({
					loading:true,
					data:{
						mod:"business",
						ctr:"business_container_list_by_customer",
						mbl:this.form.mbl.trim(),
					},
					callback:(data)=>{
						
						this.mbl_has_ser=true;
						if(data.list.length==0)return;

						//汇集集装箱列表
						let business_container_detail=[];
						for(let item of data.list){

							//货物信息
							item.cargo_info=JSON.parse(item.cargo_info);

							//集装箱列表
							business_container_detail.push({
								business_container_id:item.business_container_id,
								status:item.status,
								container_type_name:item.container_type_name,
								cargo_info:item.cargo_info,
								container_number: item.container_number,
								container_seal_number: item.container_seal_number,
							});
						}
						this.business_container_detail=business_container_detail;

						//客户
						let business_container_item=data.list[0];
					},
				});
			},

			//堆场可预约时段添加/删除
			business_container_detail_add(index = -1, data) {
				if(!this.mbl_has_ser){
					this.$my.other.msg({
						type:'warning',
						str:"请先查找booking信息"
					});
					return;
				}
				if (index == -1) {
					index = this.business_container_detail.length;
				}
				if (!data) {
					data = {
						container_type_name:'',
						cargo_info:[],
						container_number: '',
						container_seal_number: '',
					};
				}
				this.business_container_detail.splice(index, 0, {
					status:'1',
					container_type_name:data.container_type_name,
					cargo_info:data.cargo_info,
					container_number: data.container_number,
					container_seal_number: data.container_seal_number,
				});
			},
			business_container_detail_del(detail_index) {
				let business_container_item=this.business_container_detail[detail_index]
				if(business_container_item.status != 1){
					this.$my.other.msg({
						type:'warning',
						str:"此项不能删除"
					});
					return;
				}
				if(business_container_item.business_container_id){//去数据库删除

					//询问
					this.$my.other.confirm({
						content:"点击'确定'删除集装箱业务",
						confirm:()=>{

							//调用接口
							this.$my.net.req({
								data:{
									mod:"business",
									ctr:"business_container_del_by_admin",
									business_container_id:business_container_item.business_container_id,
								},
								callback:(data)=>{
									this.$my.other.msg({
										type:'success',
										str:"集装箱业务删除成功"
									});
									this.business_container_detail.splice(detail_index, 1);
								},
							});
						}
					});
				}else this.business_container_detail.splice(detail_index, 1);
			},

			//初始化
			init() {

				this.mbl_has_ser=false;

				//清空表单数据
				this.form={
					tms_ord_num:this.data.tms_ord_num,
					cus_ord_num:this.data.cus_ord_num,
					mbl:this.data.mbl,
					pod:this.data.pod,
					pickup_date:this.data.pickup_date>0?new Date(this.data.pickup_date*1000):null,
					pickup_mark:this.data.pickup_mark,
					vessel:this.data.vessel,
					voyage:this.data.voyage,
					loading_date:this.data.loading_date>0?new Date(this.data.loading_date*1000):null,
					cut_off_time:this.data.cut_off_time>0?new Date(this.data.cut_off_time*1000):null,
					etd:this.data.etd>0?new Date(this.data.etd*1000):null,
					eta:this.data.eta>0?new Date(this.data.eta*1000):null,
					atd:this.data.atd>0?new Date(this.data.atd*1000):null,
					ata:this.data.ata>0?new Date(this.data.ata*1000):null,
					clearance_release_time:this.data.clearance_release_time>0?new Date(this.data.pickup_date*1000):null,
					delivery_time:this.data.delivery_time>0?new Date(this.data.delivery_time*1000):null,
					emtpy_container_return_time:this.data.emtpy_container_return_time>0?new Date(this.data.emtpy_container_return_time*1000):null,
				}

				//清空堆场可预约时段
				this.business_container_detail = [];

				//读取
				this.mbl_ser();
			},
  		},
	};
</script>
<style lang="scss" scoped>
	.el_form_item {
		margin: 0;
	}
	.big_tit {
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
		position: relative;
	}
	.tab1_inner {
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width {
		width: 94%;
	}
	.bottomBtns {
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: calc(100% - 60px);
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form {
		overflow-y: auto;
		margin-top: -20px;
	}

	.tab_height {
		height: 450px;
	}

	.page {
		position: relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}
</style>